/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery/dist/jquery';

import '@fortawesome/fontawesome-free/js/all';

import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/js/bootstrap';

import 'slick-carousel';

import 'bootstrap-datepicker';

import 'mapbox-gl';

$(function () {
    $('.js-datepicker').datepicker({
        format: 'dd-mm-yyyy'
    });

    $('span#showmore').click(function () {
        $('.events li:hidden').slice(0, 10).show();
        if ($('.events .event').length == $('.events .event:visible').length) {
            $('span#showmore ').hide();
        }
    });

    $('.slider').slick({
        arrows: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500
        });

});
